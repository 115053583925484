/*  */

/* ********************** */
/* Below 1280 ( compatible with Smaller desktops */
/* ********************** */

@media (max-width: 80em) {
  .hero-content {
    padding-right: 8rem;
  }
  .grid {
    column-gap: 8.6rem;
  }
  .copyright {
    font-size: 1rem;
  }
}

/* ********************** */
/* Below 1232 ( compatible with Landscape mode Tablets */
/* ********************** */

@media (max-width: 77em) {
  .hero-content {
    padding-right: 6rem;
  }
  .grid {
    column-gap: 7.6rem;
  }
}

/* Below 1168 ( compatible with Landscape mode Tablets other types */
/* ********************** */

@media (max-width: 73em) {
  .hero-content {
    padding-right: 4rem;
  }
  .grid {
    column-gap: 6.6rem;
  }
}

/* ********************** */
/* Below 944px ( Tablets )*/
/* ********************** */

@media (max-width: 58em) {
  .hero-content {
    padding-right: 2rem;
  }
  .hero-text {
    font-size: 3.6rem;
  }
  .photo-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between; /* Distribute items evenly */
    gap: 1.5rem;
    max-width: 125rem;
    margin: 0 auto;
  }

  .parent {
    overflow: hidden;
    position: relative;

    width: calc(
      50% - 0.75rem
    ); /* Set width for each image (50% to make 2 images in a row) */
    height: 200px; /* Set a fixed height for all images */
  }

  .child {
    width: 100%; /* Set width of child to 100% */
    height: 100%; /* Set height of child to 100% */
    object-fit: cover;
    transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
  }

  .parent:nth-child(odd) {
    clear: both; /* Clear left float for odd elements to start a new row */
  }

  .parent:hover .child {
    transform: scale(1.1); /* Zoom in effect on hover */
  }

  .grid {
    column-gap: 3rem;
  }
  .line-2 {
    top: 104.5rem;
  }
  .pricing-plan {
    width: 90%;
  }
  .butoni-oferta {
    padding-bottom: 6rem;
  }
  .footer-heading {
    font-size: 1.6rem;
  }

  .contacts {
    font-size: 1rem;
  }
  .footer-link:link,
  .footer-link:visited {
    font-size: 1rem;
  }

  .footer-link-1:link,
  .footer-link-1:visited {
    font-size: 1rem;
  }

  .copyright {
    font-size: 0.94rem;
  }

  /* Mobile */
  .main-nav-list {
    gap: 1;
  }
  .main-nav-link,
  .main-nav-link:visited {
    font-size: 1.2rem;
  }

  nav ul {
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    flex-direction: column;
    text-align: center;
    background-color: black; /* Adjust the alpha value (0.7) to control transparency */
    gap: 0;

    opacity: 0; /* Initially hide menu */
    visibility: hidden; /* Initially hide menu */
    transition: all 0.4s ease-in;
  }
  nav ul li {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  nav ul.show {
    opacity: 1; /* Show menu */
    visibility: visible; /* Show menu */
  }

  .btn-mobile-nav {
    display: block;
  }
}

/* ********************** */
/* Below 544px ( Phones )*/
/* ********************** */

@media (max-width: 34em) {
  .header {
    padding: 0 1rem;
  }

  .section-hero {
    background-position: center -200px;
    height: 700px;
  }
  .hero-content {
    padding-right: 1rem;
  }
  .main-ikona {
    margin-right: -3rem;
    color: #76ba1b;
    width: 250px;
    height: 250px;
    background-color: transparent;
  }
  .hero-text {
    font-size: 2.4rem;
  }
  .photo-text {
    font-size: 1.9rem;
    padding-top: 4rem;
  }
  .line {
    top: 50.5rem;
  }
  .photo-container {
    gap: 1rem;
    column-gap: 1rem;
  }
  .parent {
    overflow: hidden;
    position: relative;

    width: calc(
      50% - 0.5rem
    ); /* Set width for each image (50% to make 2 images in a row) */
  }
  .plan-name {
    font-size: 1.6rem;
  }
  .plan-nameee {
    font-size: 1.6rem;
  }
  .line-2 {
    top: 87.5rem;
  }
  .pricing-plan {
    width: 100%;
  }

  .grid {
    padding-left: 1rem;
    padding-right: 1rem;
    row-gap: 4rem;
  }
  .grid--2-cols {
    grid-template-columns: 1fr;
  }
  .plan-price {
    font-size: 5.2rem;
  }
  .butoni-oferta {
    padding-bottom: 6rem;
  }
  .grid--footer {
    grid-template-columns: 1fr;
  }
  .footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .footer-heading {
    margin-bottom: 2rem;
  }
}

/*-- White Space : 
     SPACING SYSTEM (px)
    2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
  
   */

/* -- FONT SIZE SYSTEM: (px) */
/* 10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */
