* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: black;
}

.section-kontakt {
  padding-top: 9.6rem;
}

.photo-textttt {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 700;

  text-transform: uppercase;
  font-size: 3rem;
  color: white;
  text-align: center;
  position: relative;
  /* padding-bottom: 5rem; */
}
.containerrr {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 10.2rem;
  padding-bottom: 12.8rem;
  max-width: 100rem;
  margin: 0 auto;
}

.ctaaa {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  overflow: hidden;
  margin-top: 5rem;
  /* box-shadow: 0 0 3.5rem #70ef2b54; */
}

.cta-text-boxxx {
  color: black;

  padding: 4rem 3rem 4rem 5rem;
  background-color: rgba(255, 255, 255, 0.1);
}

.cta-texttt {
  font-size: 1.9rem;
  line-height: 3.2;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0);
}

.cta-nav-boxxx {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  padding-right: 0.5rem;
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.ikona-kontakt {
  color: black;
  background-color: inherit;
  font-size: 3rem;
  display: flex;
  align-items: center;
}
.ikona-gold:hover {
  transition: all 0.3s;
  color: rgb(116, 108, 108);
}

.fixxx {
  padding: 0;
}
