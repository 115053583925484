/*-- White Space : 
   SPACING SYSTEM (px)
  2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128


  -- FONT SIZE SYSTEM: (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98


  --NGJYRA kryesore: #71ef2b

 */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: black;
}

.section-info {
  padding-top: 9.6rem;
}
.line-ofertaa {
  top: 14rem;
}

.photo-texttt {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 700;

  text-transform: uppercase;
  font-size: 2.4rem;
  color: white;
  text-align: center;
  position: relative;
  padding-bottom: 5rem;
}

.center-container {
  display: grid;
  padding-top: 5rem;
  padding-bottom: 12.8rem;
  grid-template-columns: 6fr 1fr 6fr;
  place-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 125rem;
  margin: 0 auto;
}

.orari-punes {
  color: rgb(182, 182, 182);
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.6;
}

.vertical-line {
  width: 3px;
  height: 20rem;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.info-text {
  color: rgb(182, 182, 182);
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
}
